<script setup>
import { ref, onMounted } from 'vue'
import fetch from '@/fetch'
import {orderBy} from 'lodash'

// reactive state
const loading = ref(false)
const ranks = ref([])
const pid = ref(0)
const sortKey = ref('rank')
const search = ref('')
const reverse = ref(false)

// functions that mutate state and trigger updates
function fetchUser() {
    fetch('/api/v1/test', {method:'get'})
    .then(e=>{
        pid.value = e.pid;
    }).catch(error=>{
        // this.msg = {error};
        console.log(error)
    }).finally(()=> {
        loading.value = false
    });
}

function fetchData() {
    loading.value = true
    fetch('/api/v1/ranks',{method:'get'})
    .then(e=>{
        ranks.value = e.ranks;
    }).catch(error=>{
        // this.msg = {error};
        console.log(error)
    }).finally(()=> {
        loading.value = false
    });
}

function diffRank(rank, oldRank) {
    const diff = oldRank - rank;
    if (diff > 0) {
        return `▲ ${Math.abs(diff)}`
    } else if (diff < 0) {
        return `▼ ${Math.abs(diff)}`
    }
}

function sortBy(sKey) {
    reverse.value = (sKey == sortKey.value) ? ! reverse.value : false;
    sortKey.value = sKey;
}

function filteredAndSorted() {
    return orderBy(
        ranks.value
            .filter(x => (x.u_name + x.pid.toString()).toLowerCase().includes(search.value.toLowerCase()))
            .map(x => ({'u_name_lower': x.u_name.toLowerCase(), ...x}))
        ,
        sortKey.value,
        reverse.value ? 'desc' : 'asc'
    )
}

// lifecycle hooks
onMounted(() => {
    fetchUser()
    fetchData()
})
</script>

<style lang="scss" scoped>
    @import "bootstrap/scss/functions";
    @import "bootstrap/scss/variables";
    @import "bootstrap/scss/mixins";
    @import "bootstrap/scss/utilities";
    .ascend::after{
        content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-up-fill' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
    }
    .descend::after{
        content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    }
    .rising {
        color: #238823;
        font-size: .75em;
    }
    .falling {
        color: #D2222D;
        font-size: .75em;
    }
    a {
        color: white;
        text-decoration: none;
    }
</style>

<template>
    <h2>Battle Stat Rank</h2>
    <input v-model="search" class="form-control" placeholder="Filter users by name or id">
    <table class="table table-dark table-striped">
        <thead class="thead">
            <tr class="tr-default">
                <th>
                    <a
                        href="#"
                        v-on:click="sortBy('rank')"
                        v-bind:class="[
                            {'active': sortKey == 'rank'},
                            {'ascend': sortKey == 'rank' && reverse == false},
                            {'descend': sortKey == 'rank' && reverse == true}]"
                    >Rank</a>
                </th>
                <th>
                    <a
                        href="#"
                        v-on:click="sortBy('u_name_lower')"
                        v-bind:class="[
                            {'active': sortKey == 'u_name_lower'},
                            {'ascend': sortKey == 'u_name_lower' && reverse == false},
                            {'descend': sortKey == 'u_name_lower' && reverse == true}]"
                        >Player</a>
                    </th>
            </tr>
        </thead>
        <tbody>
            <tr v-bind:class="[ranking.pid == pid ? 'table-success' : 'table-dark']" v-for="ranking in filteredAndSorted()" :key="ranking.pid">
                <td>{{ranking.rank}} <span v-bind:class="[ranking.old_rank - ranking.rank > 0 ? 'rising' : 'falling']">{{diffRank(ranking.rank, ranking.old_rank)}}</span></td>
                <td>{{ranking.u_name}}[{{ranking.pid}}]</td>
            </tr>
        </tbody>
    </table>
    <span><i>Ranks updated daily. Comparison to previous week shown</i></span>
</template>